i.fa{
    display: inline-block;
    border-radius: 180px;
    box-shadow: 0px 0px 2px #888;
    padding: 0.5em 0.6em;
}
.sidebar .nav-link.active{
    color: rgb(245,180,77);
    border-left: 3px solid rgb(245,180,77);
    background-color: rgba(245, 180, 77, 0.2);
}
.sidebar .nav-link.active .nav-icon{
    color: rgb(245,180,77);
}
.sidebar .nav-item:nth-child(2) .nav-link.active{
    color: #00C1C7;
    border-color: #00C1C7;
    background-color: rgba(0, 193, 199, 0.2);
}
.sidebar .nav-item:nth-child(2) .nav-link.active .nav-icon{
    color: #00C1C7;
}
.sidebar .nav-item:nth-child(3) .nav-link.active{
    color: #D077FB;
    border-color: #D077FB;
    background-color: rgba(208, 119, 251, 0.2);
}
.sidebar .nav-item:nth-child(3) .nav-link.active .nav-icon{
    color: #D077FB;
}
.sidebar .nav-item:nth-child(4) .nav-link.active{
    color: #00DBA4;
    border-color: #00DBA4;
    background-color: rgba(0, 219, 164, 0.2);
}
.sidebar .nav-item:nth-child(4) .nav-link.active .nav-icon{
    color: #00DBA4;
}
.sidebar .nav-item:nth-child(5) .nav-link.active{
    color: rgb(157, 209, 11);
    border-color: rgb(157, 209, 11);
    background-color: rgba(157, 209, 11, 0.2);
}
.sidebar .nav-item:nth-child(5) .nav-link.active .nav-icon{
    color: rgb(157, 209, 11);
}
.sidebar .nav-item:nth-child(6) .nav-link.active{
    color: #FF8D3D;
    border-color: #FF8D3D;
    background-color: rgba(255, 141, 61, 0.2)
}
.sidebar .nav-item:nth-child(6) .nav-link.active .nav-icon{
    color: #FF8D3D;
}
.sidebar .nav-link:hover {
    color: #ffffff;
    background: rgb(60,41,114);
    border-left: 3px solid #ffffff;
}
.sidebar .nav-link:hover.active .nav-icon{
    color: #fff;
}
.breadcrumb {
    background-color: transparent !important;
    border: none;
}

.breadcrumb-item a{
    color: rgb(245,180,77);
}

.breadcrumb-item a:hover{
    color: rgb(245,180,77);
}

.breadcrumb-item.active, .breadcrumb-item.active:before{
    color: white !important;
}

@media (min-width: 992px){
    .sidebar-minimized .sidebar .nav-item:hover > .nav-link {
        background: #1b1464;
    }
    .sidebar-fixed .sidebar {
        width: 250px;
    }
}
html:not([dir="rtl"]) .sidebar {
    margin-left: -250px;
}
.sidebar .sidebar-nav, .sidebar .nav {
    width: 250px;
}
.navbar button.sidebar-minimizer {
    position: relative;
    -ms-flex: 0 0 50px;
    flex: 0 0 50px;
    cursor: pointer;
    border: 0;
    padding: 0;
}
.navbar button.sidebar-minimizer:focus{
    outline: none;
}
.navbar button.sidebar-minimizer::before{
    position: absolute;
    top: -55px;
    left: 0;
    width: 50px;
    height: 50px;
    content: "";
    background-image: url("../../Assets/images/icons/NAVBAR_TOGGLER.svg");
    background-repeat: no-repeat;
    background-position: center;
    background-size: 20px;
    transition: .3s;
}

.app-aside-toggler{
    height: 25px;
}

.aside-menu {
    background-color: rgb(23,30,59);
    border: none;
}