.ct-timeline {
    position: relative;
    margin: 0;
    padding: 0;
    width: 100%;
}

.ct-timeline .event {
    width: 100%;
    display: flex;
    flex-wrap: wrap;
    position: relative;
}

.ct-timeline .event .event-left,
.ct-timeline .event .event-right {
    flex: 0 0 50%;
    position: relative;
    padding: 10px 25px;
}

.ct-timeline .event .center-item {
    display: flex;
    align-items: center;
}

.ct-timeline .event .event-icon {
    position: absolute;
    left: calc(50% - 10px);
    top: calc(50% - 10px);
    width: 22px;
    height: 22px;
    z-index: 999;
    box-shadow: 0 0 0 4px #fff;
    border-radius: 50%;
    text-align: center;
    padding: 3px 0;
}

.ct-timeline .event .event-icon svg {
    width: 16px;
    height: 16px;
}

.ct-timeline .event .event-icon i {
    font-size: 14px;
}

.ct-timeline .event .event-right::after {
    content: '';
    left: -2px;
    top: 0;
    width: 4px;
    height: 100%;
    background: #000;
    position: absolute;
    z-index: 998;
}

.ct-timeline .event:first-child .event-right::after {
    top: 50%;
    height: 50%;
}

.ct-timeline .event:last-child .event-right::after {
    top: 0;
    height: 50%;
}

.ct-timeline .event:only-child .event-right::after {
    height: 0;
}


.ct-timeline .event .title {
    font-size: 16px;
    font-family: AvenirNext-DemiBold;
}

.ct-timeline .event .subtitle {
    font-size: 12px;
    color: #666666;
}

.ct-timeline .event p {
    font-size: 12px;
    color: #999999;
    margin: 0;
}

.ct-timeline .event-date {
    text-align: right;
    width: 100%;
}

.ct-timeline .lg-date {
    display: block;
    font-family: AvenirNext-DemiBold;
    font-size: 14px;
}

.ct-timeline .sm-date {
    display: block;
    font-size: 12px;
    color: #999999;
}

.ct-timeline .event .event-right::after,
.ct-timeline .event .event-icon {
    background: #ddd;
}


.ct-timeline .event.order_request .event-right::after,
.ct-timeline .event.order_request .event-icon,
.ct-timeline .event.bill_pending .event-right::after,
.ct-timeline .event.bill_pending .event-icon {
    background: rgb(157, 68, 107);
}

.ct-timeline .event.order_request .event-right::after,
.ct-timeline .event.bill_pending .event-right::after {
    background: rgb(157, 68, 107);
    background: linear-gradient(0deg, rgba(157, 68, 107, 0.3) 0%, rgba(157, 68, 107, 1) 50%, rgba(157, 68, 107, 0.3) 100%);
}

.ct-timeline .event.order_modify_request .event-right::after,
.ct-timeline .event.order_modify_request .event-icon {
    background: rgb(78, 34, 53);
}

.ct-timeline .event.order_modify_request .event-right::after {
    background: rgb(78, 34, 53);
    background: linear-gradient(0deg, rgba(78, 34, 53, 0.3) 0%, rgba(78, 34, 53, 1) 50%, rgba(78, 34, 53, 0.3) 100%);
}

.ct-timeline .event.bill_approved .event-right::after,
.ct-timeline .event.bill_approved .event-icon,
.ct-timeline .event.order_accept .event-right::after,
.ct-timeline .event.order_accept .event-icon,
.ct-timeline .event.order_modify_accept .event-right::after,
.ct-timeline .event.order_modify_accept .event-icon,
.ct-timeline .event.bill_completed .event-right::after,
.ct-timeline .event.bill_completed .event-icon {
    background: rgb(0, 143, 41);
}

.ct-timeline .event.bill_approved .event-right::after,
.ct-timeline .event.order_accept .event-right::after,
.ct-timeline .event.order_modify_accept .event-right::after,
.ct-timeline .event.bill_completed .event-right::after {
    background: rgb(0, 143, 41);
    background: linear-gradient(0deg, rgba(0, 143, 41, 0.3) 0%, rgba(0, 143, 41, 1) 50%, rgba(0, 143, 41, 0.3) 100%);
}

.ct-timeline .event.bill_unapproved .event-right::after,
.ct-timeline .event.bill_unapproved .event-icon,
.ct-timeline .event.order_reject .event-right::after,
.ct-timeline .event.order_reject .event-icon,
.ct-timeline .event.order_modify_reject .event-right::after,
.ct-timeline .event.order_modify_reject .event-icon,
.ct-timeline .event.bill_payment_refunded .event-right::after,
.ct-timeline .event.bill_payment_refunded .event-icon,
.ct-timeline .event.bill_cancelled .event-right::after,
.ct-timeline .event.bill_cancelled .event-icon {
    background: rgb(206, 41, 29);
}

.ct-timeline .event.bill_unapproved .event-right::after,
.ct-timeline .event.order_reject .event-right::after,
.ct-timeline .event.order_modify_reject .event-right::after,
.ct-timeline .event.bill_payment_refunded .event-right::after,
.ct-timeline .event.bill_cancelled .event-right::after  {
    background: rgb(206, 41, 29);
    background: linear-gradient(0deg, rgba(206, 41, 29, 0.3) 0%, rgba(206, 41, 29, 1) 50%, rgba(206, 41, 29, 0.3) 100%);
}

.ct-timeline .event.profile_updated .event-right::after,
.ct-timeline .event.profile_updated .event-icon,
.ct-timeline .event.bill_created .event-right::after,
.ct-timeline .event.bill_created .event-icon {
    background: rgb(92, 181, 232);
}

.ct-timeline .event.profile_updated .event-right::after,
.ct-timeline .event.bill_created .event-right::after {
    background: rgb(92, 181, 232);
    background: linear-gradient(0deg, rgba(92, 181, 232, 0.3) 0%, rgba(92, 181, 232, 1) 50%, rgba(92, 181, 232, 0.3) 100%);
}

.ct-timeline .event.bill_payment_expected .event-right::after,
.ct-timeline .event.bill_payment_expected .event-icon {
    background: rgb(244, 148, 0);
}

.ct-timeline .event.bill_payment_expected .event-right::after {
    background: rgb(244, 148, 0);
    background: linear-gradient(0deg, rgba(244, 148, 0, 0.3) 0%, rgba(244, 148, 0, 1) 50%, rgba(244, 148, 0, 0.3) 100%);
}



.ct-minitimeline {
    position: relative;
    margin: 0;
    padding: 0;
    width: 100%;
}

.ct-minitimeline .event {
    width: 100%;
    display: flex;
    flex-wrap: wrap;
    position: relative;
}

.ct-minitimeline .event .event-content {
    flex: 0 0 100%;
    position: relative;
    padding: 0px 15px 5px 15px;
}

.ct-minitimeline .event .event-icon {
    position: absolute;
    left: 0;
    top: 3px;
    width: 10px;
    height: 10px;
    z-index: 999;
    box-shadow: 0 0 0 1px #fff;
    border-radius: 50%;
    text-align: center;
    padding: 0;
    background: #25a9e5;
}

.ct-minitimeline .event .event-icon svg {
    width: 6px;
    height: 6px;
    top: -7px;
    position: relative;
}

.ct-minitimeline .event .event-icon i {
    font-size: 6px;
}

.ct-minitimeline .event .event-content::after {
    content: '';
    left: 3px;
    top: 0;
    width: 3px;
    height: 100%;
    background: #ddd;
    position: absolute;
    z-index: 998;
}

.ct-minitimeline .event:first-child .event-content::after {
    top: 5px;
}

.ct-minitimeline .event:last-child .event-content::after {
    height: 5px;
}

.ct-minitimeline .event .title {
    font-size: 12px;
    font-family: AvenirNext-DemiBold;
}

.ct-minitimeline .event .subtitle {
    font-size: 11px;
}

.ct-minitimeline .event p {
    font-size: 11px;
}