@media (min-width: 992px) {
    .sidebar-fixed .app-header+.app-body .sidebar {
        height: calc(100vh - 55px);
    }
}

@media (max-width: 991.98px) {
    .app-body {
        margin-top: 0;
    }

    .navbar-brand-minimized {
        visibility: visible;
    }

    .navbar-brand-full {
        visibility: hidden;
    }
    .sidebar .sidebar-nav, .sidebar .nav {
        width: 200px;
    }
}

@media (min-width: 991.98px) {
    .navbar-brand-full {
        visibility: visible;
        width: 100%;
    }

    .navbar-brand {
        width: 200px !important;
    }

    .brand-minimized .navbar-brand {
        width: 50px !important;
    }

    html:not([dir="rtl"]) .sidebar-lg-show.sidebar-fixed .main, html:not([dir="rtl"]) .sidebar-lg-show.sidebar-fixed .app-footer, html:not([dir="rtl"]) .sidebar-show.sidebar-fixed .main, html:not([dir="rtl"]) .sidebar-show.sidebar-fixed .app-footer {
        margin-left: 250px;
    }
}
