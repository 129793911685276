.distributor-users{
    width: 100%;
    height: 70px;
    display: inline-block;
    padding: 10px;
    margin: 5px;
    background-color: #f2f4f8;
    border-radius: 5px;
    text-align: left;
    cursor: pointer;
    position: relative;
}

.distributor-users > div{
    display: inline-block;
    height: 50px;
    vertical-align: middle;
}

.distributor-users > div:first-child{
    width: 4px;
    border-radius: 5px;
    position: absolute;
    left: 5px;
}

.distributor-users > div:nth-child(2){
    padding: 5px;
}

.distributor-users .company-name{
    margin-bottom: 10px;
    color: #1b1464;
    font-weight: bold;
}

.distributor-users:hover{
    box-shadow: 0 0 5px 1px rgba(156,2,93,1);
}