.order-container .oc-left-form {
    min-height: 42vh;
}

.order-container .oc-left-license {
    min-height: 40vh;
}

.order-container .oc-left-button {
    min-height: 6vh;
}

.order-container .oc-left-payment {
    min-height: 83vh;
}

.order-container .sidebar-balance-box-content {
    min-height: 9vh;
}

.order-container .oc-right-detail {
    min-height: 80vh;
}

.order-container .oc-left-list {
    min-height: 90vh;
}

.license-detail-text {
    text-align: center;
    margin-top: 10px;
}

.oc-order-modal {
    margin-top: 25vh !important;
}

@media (min-width: 650px) {
    .oc-order-modal {
        max-width: 630px !important;
    }
}

.oc-order-modal .modal-content {
    border: 0;
    border-radius: 10px;
}

.oc-order-modal .modal-content .modal-body {
    padding: 10px;
}

.oc-order-modal .modal-content .modal-body .modal-left {
    position: relative;
    width: 175px;
}

.oc-order-modal .modal-content .modal-body .modal-left {
    position: relative;
    width: 175px;
    float: left;
}

.oc-order-modal .modal-content .modal-body .modal-left img {
    width: 100%;
}

.oc-order-modal .modal-content .modal-body .modal-right {
    position: relative;
    width: calc(100% - 175px);
    float: left;
    padding-left: 15px;
    text-align: center;
}

.oc-order-modal .modal-content h4 {
    font-family: "AvenirNext-Bold";
    font-size: 16px;
    color: #4c4c4c;
}

.oc-order-modal .modal-content p {
    font-size: 12px;
    color: #666666;
}


.oc-order-modal .modal-content .ct-modal-list {
    list-style: none;
    margin: 0;
    padding: 0;
    width: 100%;
    margin-bottom: 30px;
}

.oc-order-modal .modal-content .ct-modal-list li {
    font-weight: bold;
    text-align: left;
    font-size: 10px;
    color: #303030;
}

.oc-order-modal .modal-content .ct-modal-list li span {
    color: #20abe6;
    font-weight: normal;
}

.oc-order-modal .modal-content .ct-modal-bottomtext {
    margin-bottom: 30px;
    font-size: 13px;
}



.order-container h1,
.order-container h2,
.order-container h3,
.order-container h4,
.order-container .oc-left-license label,
.order-container .oc-left-payment label {
    text-align: center;
}

.order-accordion, .commitment-accordion {
    width: 100%;
    border: 1px solid #d4d4d4;
    padding: 10px 5px;
    cursor: pointer;
}

.order-accordion>div {
    display: inline-block;
    width: 20%;
    padding: 5px 10px;
    vertical-align: middle;
}

.order-accordion>div:nth-child(2) {
    width: 30%;
}

.order-accordion>div:first-child, .order-accordion>div:last-child {
    width: 15% !important;
}

.order-accordion>div:last-child {
    text-align: right;
}

.order-accordion.active, .commitment-accordion.active {
    border-bottom: none;
    margin-bottom: 0;
}

.commitment-accordion > .row > div:last-child {
    text-align: right;
}

.collapse, .collapsing {
    border: 1px solid #d4d4d4;
    border-top: none;
}

.collapse > div:first-child, .collapsing > div:first-child {
    padding: 10px;
}

.order-status.cancelled,
.order-status.unapproved {
    color: #9e9e9d;
}

.order-status.active,
.order-status.approved {
    color: #008f29;
}

.order-status.deactive {
    color: rgb(36, 36, 36);
}

.order-status.expired {
    color: #ff8800;
}

.order-status.pending {
    color: #9d446b;
}

.order-accordion [data-icon^="chevron"] {
    color: #000000;
    background-color: #d4d4d4;
    border-radius: 30px;
    width: 40px;
    height: 40px;
    padding: 5px;
}

.order-form-container .custom-switch {
    margin: 0 auto;
}

.commitment-card {
    max-width: 150px !important;
    min-width: 150px !important;
}