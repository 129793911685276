.sidebar-balance-box {
    margin-right: -3px !important;
    margin-left: -3px !important;
}

.sidebar-balance-box .sbb-buttonbox {
    padding-right: 3px;
    padding-left: 3px;
    text-align: center;
}

.sidebar-balance-box .sbb-buttonbox span {
    font-size: 12px;
    margin-bottom: 3px;
}

.sidebar-balance-box .sbb-buttonbox button {
    font-family: AvenirNext-Bold;
    font-size: 18px;
}

.sidebar-balance-box .sbb-buttonbox .invoice {
    background: linear-gradient(0deg, #ac0d00 0, #f93f3e 100%);
    border: 0;
}

.sidebar-balance-box .sbb-buttonbox .balance {
    background: linear-gradient(0deg, #87cdfb 0, #2c99c0 100%);
    border: 0;
}

.ct-sidebar-list {
    list-style: none;
    margin: 0;
    padding: 0;
    width: 100%;
    margin-bottom: 10px;
}

.ct-sidebar-list li {
    font-weight: bold;
}

.ct-sidebar-list li span {
    color: #2c99c0;
}