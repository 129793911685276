.ct-icon{
    -webkit-font-smoothing: antialiased;
    display: inline-block !important;
    font-style: normal;
    font-variant: normal;
    text-rendering: auto;
    line-height: 1;
    vertical-align: middle;
    background-position: center !important;
}
.ct-icon-lg{
    width: 50px;
    height: 50px;
}
.ct-icon-md{
    width: 30px;
    height: 30px;
}
.ct-icon-sm{
    width: 20px;
    height: 20px;
}
.ct-icon-xs{
    width: 10px;
    height: 10px;
}
.ct-home{
    background: url(../images/icons/HOME.svg);
    background-repeat: no-repeat;
    background-size: auto;
}
.ct-distributor{
    background: url(../images/icons/DISTRIBUTOR.svg);
    background-repeat: no-repeat;
    background-size: auto;
}
.ct-demo{
    background: url(../images/icons/DEMO.svg);
    background-repeat: no-repeat;
    background-size: auto;
}
.ct-license{
    background: url(../images/icons/LISANS.svg);
    background-repeat: no-repeat;
    background-size: auto;
}
.ct-current{
    background: url(../images/icons/CARI.svg);
    background-repeat: no-repeat;
    background-size: auto;
}
.ct-order{
    background: url(../images/icons/CARI.svg);
    background-repeat: no-repeat;
    background-size: auto;
}
.ct-bill{
    background: url(../images/icons/URUNLER.svg);
    background-repeat: no-repeat;
    background-size: auto;
}
.ct-setting{
    background: url(../images/icons/AYARLAR.svg);
    background-repeat: no-repeat;
    background-size: auto;
}
