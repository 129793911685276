.ct-chart {
    position: relative;
    width: 100%;
    background: #dee4ec;
    padding: 10px;
}

.ct-chart .chart1,
.ct-chart .chart2 {
    width: 100%;
    height: 380px !important;
}

.ct-chart .chart4 {
    width: 100%;
    height: 170px !important;
}

.ct-chart .chart5 {
    width: 100%;
    height: 220px !important;
}

.ct-chart .chart4 {
    margin: 0 auto;
    width: calc(100% - 40px) !important;
}

.ct-chart .top-toolbar {
    position: relative;
    width: 100%;
    margin-bottom: 20px;
    padding: 0 5px;
    overflow: hidden;
}

.ct-chart .top-toolbar .legends {
    position: relative;
    width: 50%;
    float: left;
}

.ct-chart .top-toolbar .full-legends {
    position: relative;
    width: 100%;
    float: left;
    text-align: center;
}

.ct-chart .top-toolbar .legends ul {
    list-style: none;
    margin: 0;
    padding: 0;
}

.ct-chart .top-toolbar .legends ul li {
    display: inline-block;
    padding: 5px 8px;
    border-radius: 22px;
    font-size: 12px;
    background: #fff;
    margin-right: 10px;
    cursor: pointer;
    border: 1px solid transparent;
}

.ct-chart .top-toolbar .date-select {
    position: relative;
    width: 50%;
    float: left;
}

.ct-chart .top-toolbar .date-select ul {
    list-style: none;
    margin: 0;
    padding: 0;
    float: right;
}

.ct-chart .top-toolbar .date-select ul li {
    display: inline-block;
    padding: 5px 8px;
    font-size: 12px;
    background: #fff;
    margin-right: 10px;
    cursor: pointer;
    width: 80px;
    text-align: center;
}

.ct-chart .top-toolbar .date-select ul li.active {
    background: #000;
    color: #fff;
}

.ct-barchart {
    width: 100%;
    margin-bottom: 10px;
}

.ct-barchart .ct-barchart-info {
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
}

.ct-barchart .ct-barchart-info .text-left {
    width: 70%;
}

.ct-barchart .ct-barchart-info .text-right {
    width: 30%;
}

.ct-barchart .ct-barchart-info span {
    font-size: 10px;
    font-family: AvenirNext-Medium;
    color: #000;
}

.ct-barchart .ct-barchart-info .text-right span {
    font-family: AvenirNext-Bold;
}

.ct-barchart .baseline {
    width: 100%;
    height: 2px;
    background-color: #e1e1e1;
    position: relative;
}

.ct-barchart .baseline .percentage {
    position: absolute;
    right: 0;
    height: 7px;
    top: -2.5px;
    border-radius: 6px;
}

.unselect-legend {
    background-color: #d0d0d0 !important;
    border-color: #afafaf !important;
}