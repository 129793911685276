.table .sortable {
    cursor: pointer;
}



.ct-table .badge {
    padding: 5px 10px;
    border-radius: 15px;
}

.ct-table .badge-status {
    padding: 20px;
    background: #9e9e9d;
    color: #fff;
    width: 110px;
    word-break: break-word;
    white-space: normal;
    line-height: 15px;
}

.ct-table .green-title {
    color: #008f29;
    font-size: 18px;
    font-family: AvenirNext-DemiBold;
}

.ct-table .bold-title {
    font-size: 14px;
    font-family: AvenirNext-Bold;
}

.ct-table .btn {
    padding: 7.5px 15px;
    border-radius: 5px;
    font-size: 75%;
    font-weight: 700;
    line-height: 1;
}

.ct-table .btn-primary {
    color: #fff;
    background-color: #166fbd;
}

.ct-table .ct-table-action {
    min-width: 100px;
    text-align: center;
    padding-left: 0;
    padding-right: 0;
}

.ct-table .ct-table-action.btn-2 {
    min-width: 135px;
}

.ct-table .ct-table-action.btn-3 {
    min-width: 165px;
}

.ct-table .ct-table-action.btn-4 {
    min-width: 225px;
}

.ct-table .ct-table-action .btn {
    margin-right: 10px;
}

.ct-table .ct-table-action .btn:last-child {
    margin-right: 0;
}


.ct-table-style-1 td,
.ct-table-style-1 tbody th {
    vertical-align: middle;
}

.ct-table-style-1 thead th {
    border-bottom: 1px solid #e9e9e9;
    border-top: 0;
    color: #d70c5a;
    padding-bottom: 5px;
    padding-top: 10px;
}

.ct-table-style-1 tbody th,
.ct-table-style-1 tbody td {
    border-bottom: 1px solid #e9e9e9;
}

.ct-table-style-1 p {
    margin: 0;
    padding: 0;
    line-height: 14px;
    font-size: 12px;
}

.ct-table-style-2 {
    border: 0;
}

.ct-table-style-2 tbody tr {
    border: 0;
    background-color: #f8f8f8;
}

.ct-table-style-2 tbody tr:nth-of-type(odd) {
    background-color: #fff;
}

.ct-table-style-2 thead tr {
    border-bottom: 1px solid #eee;
}

.ct-table-style-2 tbody tr td,
.ct-table-style-2 tbody tr th,
.ct-table-style-2 thead tr th,
.ct-table-style-2 thead tr th {
    border: 0;
    vertical-align: middle;
}

.ct-table-style-2 tbody tr td,
.ct-table-style-2 tbody tr th {
    padding: 8px 12px;
    color: #4c4c4c;
    font-family: AvenirNext-DemiBold;
}

.ct-table-style-2 thead tr th,
.ct-table-style-2 thead tr th {
    font-family: AvenirNext-DemiBold;
    font-size: 16px;
}

.ct-table-style-2.ct-table-style-2-white tbody tr {
    background-color: #fff;
}

.ct-table-style-2 .badge-status {
    padding: 8px 10px;
    border-radius: 12px;
}

.ct-table-style-3 {
    border: 0;
    border-bottom-left-radius: 10px;
    overflow: hidden;
}

.ct-table-style-3 tbody tr {
    border: 0;
    background-color: #666666;
}

.ct-table-style-3 tbody tr:nth-of-type(odd) {
    background-color: #666666;
}

.ct-table-style-3 tbody tr td,
.ct-table-style-3 tbody tr th,
.ct-table-style-3 thead tr th,
.ct-table-style-3 thead tr th {
    border: 0;
    vertical-align: middle;
}

.ct-table-style-3 tbody tr td,
.ct-table-style-3 tbody tr th {
    padding: 8px 12px;
    color: #e4e6e6;
    font-family: AvenirNext-DemiBold;
}

.ct-table-style-3 thead tr th,
.ct-table-style-3 thead tr th {
    font-family: AvenirNext-DemiBold;
    font-size: 16px;
}

.ct-table-style-sub tbody tr {
    background-color: #fff;
}

.ct-table tr.selected, .ct-table tr.non-selected{
    cursor: pointer;
}

.ct-table tr.selected{
    background: #eee;
}