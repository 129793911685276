.ct-autocomplete {
    position: relative;
    display: inline-block;
    width: 100%;
}

.ct-autocomplete-items {
    position: absolute;
    border: 1px solid #d4d4d4;
    border-bottom: none;
    border-top: none;
    z-index: 99;
    top: 100%;
    left: 0;
    right: 0;
    list-style: none;
    margin: 0;
    padding: 0;
}

.ct-autocomplete-items li {
    padding: 4px 10px;
    cursor: pointer;
    background-color: #fff;
    border-bottom: 1px solid #d4d4d4;
}

.ct-autocomplete-items li:hover, .ct-autocomplete-items li.on-focus {
    background-color: #e9e9e9;
}