.wr-loading {
    position: fixed;
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, 0.9);
    z-index: 9999999;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
}

.wr-loading .wr-loading-content {
    width: 187px;
    margin: 0 auto;
    margin-top: 45vh;
    font-size: 24px;
    color: #fff;
    font-family: AvenirNext-Bold;
}

.wr-loading .wr-loading-content img {
    width: 187px;
    height: 150px;
}

.wr-loading.hide {
    visibility: hidden;
    opacity: 0;
    transition: visibility 0.5s, opacity 0.5s linear;
}

.wr-loading.show {
    visibility: visible;
    opacity: 1;
    transition: visibility 0.5s, opacity 0.5s linear;
}