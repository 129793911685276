html,
body,
.App {
    height: 100vh;
}

.form-control {
    font-size: 0.8rem !important;
    height: 36px;
}

.login-container {
    height: 100vh;
    background: linear-gradient(to bottom, #9e005d, #1b1464) !important;
    color: #FFFFFF !important;
}

.login-container ::placeholder {
    /* Chrome, Firefox, Opera, Safari 10.1+ */
    color: #FFFFFF !important;
    opacity: 1;
    /* Firefox */
}

.login-container :-ms-input-placeholder {
    /* Internet Explorer 10-11 */
    color: #FFFFFF !important;
}

.login-container ::-ms-input-placeholder {
    /* Microsoft Edge */
    color: #FFFFFF !important;
}

.auth-form {
    max-width: 500px;
    border: 1px solid white;
    padding: 20px 45px;
    background: linear-gradient(to bottom, #1b1464, #9e005d);
    position: absolute;
    left: 0;
    right: 0;
    margin: auto;
    border-radius: 20px;
    height: auto;
    font-family: AvenirNext-UltraLight;
    -webkit-box-shadow: 0px 7px 30px 6px rgba(0, 0, 0, 0.75);
    -moz-box-shadow: 0px 7px 30px 6px rgba(0, 0, 0, 0.75);
    box-shadow: 0px 7px 30px 6px rgba(0, 0, 0, 0.75);
    transform: translateY(-50%);
    top: 50%;
}

.auth-form h1 {
    font-family: AvenirNext-UltraLight;
    font-size: 46px;
    margin-bottom: 10px;
}

.auth-form p {
    font-family: AvenirNext-Medium;
    font-size: 12.6px;
    margin-bottom: 0;
}

.auth-form p a {
    text-decoration: underline;
}


.btn-login {
    font-family: AvenirNext-UltraLight;
    padding: 5px 0 !important;
    margin-bottom: 30px;
    -webkit-border-radius: 0 0 10px 10px !important;
    -moz-border-radius: 0 0 10px 10px !important;
    border-radius: 0 0 10px 10px !important;
    font-weight: bold !important;
}

.login-container .input-form .form-group {
    width: 50%;
    display: inline-block;
    margin: 0;
}

.login-container .input-form .form-group {
    width: 50%;
    display: inline-block;
    margin: 0;
}

.login-input {
    padding: 10px 20px 10px 10px !important;
    background-color: rgb(145, 85, 145) !important;
    font-family: AvenirNext-Italic, "Font Awesome 5 Free";
    font-weight: 900;
    border: none !important;
    color: #FFFFFF !important;
}

.login-email>div>span {
    -webkit-border-radius: 10px 0 0 0 !important;
    -moz-border-radius: 10px 0 0 0 !important;
    border-radius: 10px 0 0 0 !important;
    background-color: rgb(145, 85, 145) !important;
    color: #FFFFFF;
    border: none;
}

.login-email>input {
    -webkit-border-radius: 0 0 0 0 !important;
    -moz-border-radius: 0 0 0 0 !important;
    border-radius: 0 0 0 0 !important;
}

.login-password>input {
    -webkit-border-radius: 0 10px 0 0 !important;
    -moz-border-radius: 0 10px 0 0 !important;
    border-radius: 0 10px 0 0 !important;
}

.login-password>div>span {
    -webkit-border-radius: 0;
    -moz-border-radius: 0;
    border-radius: 0;
    background-color: rgb(145, 85, 145) !important;
    color: #FFFFFF;
    border: none;
}

.login-input:focus {
    color: #ffffff !important;
    border-color: rgba(205, 207, 210, 0.50) !important;
    -webkit-box-shadow: 0 0 0 0.2rem rgba(205, 207, 210, 0.25) !important;
    box-shadow: 0 0 0 0.2rem rgba(212, 212, 212, 0.25) !important;
}

.auth-form .is-invalid>div>span {
    border: 2px solid rgba(220, 53, 69, 1) !important;
    border-right: 0 !important;
}

.auth-form .is-invalid .login-input {
    border: 2px solid rgba(220, 53, 69, 1) !important;
    border-left: 0 !important;
}


.form-group.w-100 .login-input {
    -webkit-border-radius: 0 10px 0 0 !important;
    -moz-border-radius: 0 10px 0 0 !important;
    border-radius: 0 10px 0 0 !important;
}

/* Change the white to any color ;) */
input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus,
input:-webkit-autofill:active {
    -webkit-box-shadow: 0 0 0 30px rgb(145, 85, 145) inset !important;
    -webkit-text-fill-color: rgb(255, 255, 255) !important;
}

.login-container .input-form {
    margin-top: 30px;
}

.forgotpassword {
    text-align: right;
}

.forgotpassword a {
    font-family: AvenirNext-UltraLight;
    text-decoration: underline;
    font-weight: bold;
    font-size: 14px;
    color: #FFFFFF;
}

.wr-notfound-alert {
    margin-top: 25px;
}

.loginlink a {
    float: right;
    text-decoration: underline;
    font-family: AvenirNext-Medium;
    text-decoration: underline;
    font-weight: bold;
    font-size: 14px;
}

.ct-checkbox {
    position: relative;
    display: block;
    margin-bottom: 10px;
    margin-top: -5px;
    padding-left: 20px;
    padding-left: 20px;
}

.ct-checkbox label {
    min-height: 20px;
    margin-bottom: 0;
    cursor: pointer;
    display: inline-block;
    vertical-align: middle;
    position: relative;
    padding-left: 5px;
    font-size: 14px;
    text-decoration: underline;
    font-weight: bold;
}

.ct-checkbox label::before {
    content: "";
    display: inline-block;
    position: absolute;
    width: 20px;
    height: 20px;
    left: 0;
    margin-left: -20px;
    border: 1px solid #FFFFFF;
    border-radius: 0;
    background-color: #fff0;
    -webkit-transition: border 0.15s ease-in-out;
    -o-transition: border 0.15s ease-in-out;
    transition: border 0.15s ease-in-out;
}

.ct-checkbox label::after {
    display: inline-block;
    position: absolute;
    content: " ";
    width: 14px;
    height: 14px;
    left: 3px;
    top: 3px;
    margin-left: -20px;
    border-radius: 0;
    background-color: #FFFFFF;
    -webkit-transform: scale(0, 0);
    -ms-transform: scale(0, 0);
    -o-transform: scale(0, 0);
    transform: scale(0, 0);

}

.ct-checkbox input[type="checkbox"] {
    opacity: 0;
    z-index: 1;
}

.ct-checkbox input[type="checkbox"]:focus+label::before {
    outline: thin dotted;
    outline: 5px auto -webkit-focus-ring-color;
    outline-offset: -2px;
}

.ct-checkbox input[type="checkbox"]:checked+label::after {
    -webkit-transform: scale(1, 1);
    -ms-transform: scale(1, 1);
    -o-transform: scale(1, 1);
    transform: scale(1, 1);
}

.loginlink a {
    float: left;
    font-family: AvenirNext-Medium;
    text-decoration: underline;
    font-weight: bold;
    font-size: 14px;
    color: #FFFFFF;
}

.form-logo {
    max-height: 90px;
}

.text-danger-login {
    color: #ffffff !important;
    background-color: #dc3545;
    width: 100%;
    border-radius: 5px;
    padding: 0 10px;
    display: inline-block;
}