.ct-box-list {
    width: 100%;
}

.ct-box-list .single-box {
    min-width: 220px;
    width: calc(20% - 10px);
    height: 140px;
    display: inline-block;
    margin: 5px;
    background-color: #f2f4f8;
    border-radius: 5px;
    text-align: left;
    cursor: pointer;
    position: relative;
}

.ct-box-list .single-box:hover {
    box-shadow: 0 0 5px 1px rgba(156, 2, 93, 1);
}

.ct-box-list .style-color {
    display: inline-block;
    height: 120px;
    vertical-align: middle;
    width: 4px;
    border-radius: 5px;
    margin: 10px 0 10px 10px;
}

.ct-box-list .single-box-content {
    display: inline-block;
    height: 120px;
    vertical-align: middle;
    padding: 5px;
}
.ct-box-list .single-box .single-box-content{
    width: calc(100% - 44px);
}

.ct-box-list .single-box-content .title {
    margin-bottom: 10px;
    color: #1b1464;
    font-weight: bold;
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap;
}

.ct-box-list .single-box-content .sub-title p {
    margin-bottom: 0;
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap;
}

.ct-box-list .single-box-content .btn {
    border-radius: 10px;
    color: #fff;
    padding: 0 10px;
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap;
}

.ct-box-list .single-box .button-div {
    display: inline-block;
    height: 100%;
    width: 0px;
    transition: all 0.5s linear;
}

.ct-box-list .single-box:hover .button-div {
    width: 30px;
    opacity: 1;
}

.ct-box-list .single-box .btn-detail {
    opacity: 0;
    z-index: -5;
    transition-timing-function: linear, step-end, step-end;
}

.ct-box-list .single-box:hover .btn-detail {
    transition: opacity 0.5s linear, margin-top 0.5s step-start, z-index 0.5s step-start;
    display: block;
    height: 100%;
    border-radius: 5px 0 0 5px;
    opacity: 1;
}

.ct-box-load-more {
    width: 100%;
    text-align: center;
}

.ct-box-load-more .btn {
    background: transparent;
    color: #000;
    font-size: 18px;
    font-weight: bold;
}