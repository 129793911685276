.ct-pagination {
    width: 100%;
    position: relative;
    overflow: hidden;
}

.ct-pagination>div {
    width: 33.3%;
    display: inline-block;
}

.ct-pagination .pagination-limit {
    float: left;
    text-align: left;
}

.ct-pagination select {
    max-width: 100px !important;
}

.ct-pagination .pagination-limit select {
    min-width: 100px;
    border: 1px solid #f4f4f4;
    background: #f9f9f9;
    color: #000;
    font-family: AvenirNext;
    font-size: 12px;
    border-radius: 0;
    padding: .4rem .75rem;
    height: auto;
}

.ct-pagination .pagination-info {
    float: left;
    line-height: 32px;
}

.ct-pagination .pagination {
    float: right;
    margin: 0;
}

.ct-pagination .pagination .page-item .page-link {
    padding: .5rem .75rem;
    border-radius: 0;
    border: 1px solid #f4f4f4;
    background: #f9f9f9;
    color: #000;
    font-family: AvenirNext;
    font-size: 12px;
    margin-left: 5px;
    cursor: pointer;
}

.ct-pagination .pagination .page-item.active .page-link {
    color: #f9f9f9;
    background: #000;
}

.ct-pagination-style-1 .pagination .page-item.active .page-link {
    background: #d70c5a;
}

.ct-pagination-style-1 .pagination .page-item .page-link {
    border: 1px solid rgba(215, 12, 90, 0.3);
    border-radius: 3px;
}

.ct-pagination-style-1 .pagination-limit select {
    border: 1px solid rgba(215, 12, 90, 0.3);
    border-radius: 3px;
}