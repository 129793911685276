@font-face {
    font-family: "ConthraxSb-Regular";
    src: url('../fonts/conthraxsb-regular.woff') format('woff');
}

@font-face {
    font-family: "AvenirNext";
    src: url('../fonts/AvenirNext-Regular.woff') format('woff');
}

@font-face {
    font-family: "AvenirNext-Bold";
    src: url('../fonts/AvenirNext-Bold.woff') format('woff');
}

@font-face {
    font-family: "AvenirNext-BoldItalic";
    src: url('../fonts/AvenirNext-BoldItalic.woff') format('woff');
}

@font-face {
    font-family: "AvenirNext-DemiBold";
    src: url('../fonts/AvenirNext-DemiBold.woff') format('woff');
}

@font-face {
    font-family: "AvenirNext-DemiBoldItalic";
    src: url('../fonts/AvenirNext-DemiBoldItalic.woff') format('woff');
}

@font-face {
    font-family: "AvenirNext-Heavy";
    src: url('../fonts/AvenirNext-Heavy.woff') format('woff');
}

@font-face {
    font-family: "AvenirNext-HeavyItalic";
    src: url('../fonts/AvenirNext-HeavyItalic.woff') format('woff');
}

@font-face {
    font-family: "AvenirNext-Italic";
    src: url('../fonts/AvenirNext-Italic.woff') format('woff');
}

@font-face {
    font-family: "AvenirNext-Medium";
    src: url('../fonts/AvenirNext-Medium.woff') format('woff');
}

@font-face {
    font-family: "AvenirNext-MediumItalic";
    src: url('../fonts/AvenirNext-MediumItalic.woff') format('woff');
}

@font-face {
    font-family: "AvenirNext-UltraLight";
    src: url('../fonts/AvenirNext-UltraLight.woff') format('woff');
}

@font-face {
    font-family: "AvenirNext-UltraLightItalic";
    src: url('../fonts/AvenirNext-UltraLightItalic.woff') format('woff');
}

html,
body {
    font-family: "AvenirNext";
    background: rgb(242, 244, 248) !important;
}

::-webkit-scrollbar-track {
    box-shadow: inset 0 0 3px rgba(0, 0, 0, 0.3);
    background-color: #F5F5F5;
}

::-webkit-scrollbar {
    width: 5px;
    background-color: #F5F5F5;
    border-radius: 0 0 0 0;
}

::-webkit-scrollbar-thumb {
    background-color: #dbccd9;
}

.form-control:focus,
.custom-select:focus {
    box-shadow: 0 0 0 1px rgba(0, 123, 255, .25);
}

.input-group .input-group-text,
.input-group>.form-control {
    border-radius: 0;
}

.react-phone-number-input__icon-image,
.react-phone-number-input__icon svg {
    float: left;
}

.react-datetime-picker,
.react-datetime-picker__wrapper {
    width: 100%;
}

.react-datetime-picker__wrapper {
    border-radius: 0;
    font-size: 13px !important;
    border: 1px solid #dcdcdc;
}

.input-group .react-datetime-picker {
    padding: 0;
}

.input-group .react-datetime-picker__wrapper {
    height: 100%;
    border: 0;
}

.input-group .input-group-prepend .form-check-input {
    position: relative;
    margin: 0;
    height: auto;
}

input.react-datetime-picker__inputGroup__input.react-datetime-picker__inputGroup__input--hasLeadingZero {
    padding-left: calc(1px + 0.54em) !important;
}

input.react-datetime-picker__inputGroup__year {
    padding-left: 0 !important;
    min-width: 31px;
}

.react-datetime-picker--disabled, .react-phone-number-input__input--disabled {
    background-color: #e9ecef;
    opacity: 1;
}

@import "~slick-carousel/slick/slick.css";
@import "~slick-carousel/slick/slick-theme.css";

.badge-primary {
    color: #fff;
    background-color: #166fbd;
}



.ct-version-code {
    color: black;
    opacity: 0.4;
    position: fixed;
    right: 5px;
    bottom: 5px;
    font-size: 14px;
}

.App,
#root {
    position: relative;
    font-size: 0.8rem;
}

.master-layout {
    position: relative;
    width: 100%;
    margin: 0;
    padding: 0;
    max-height: 100vh;
    /*overflow-y: scroll;overflow-y: auto;*/
}

.breadcrumb {
    padding: 5px 16px !important;
    margin: 0 !important;
}

.sidebar-fixed .sidebar {
    height: 100%;
    background: linear-gradient(to bottom, #1b1464, #9e005d);
}


.d-md-down-none.navbar-toggler {
    margin-left: 0;
    transition: margin-left .25s, margin-right .25s, width .25s, flex .25s;
    transition: margin-left .25s, margin-right .25s, width .25s, flex .25s, -ms-flex .25s
}

.navbar-brand {
    margin: 0 !important;
}

.app-header {
    background-color: #1b1464;
}

.custom-checkbox {
    float: left;
}

.navbar {
    padding: 0;
}

.app-header {
    border: none;
}

.modal-backdrop.show {
    background: #ececec;
    opacity: .7;
}


.master-content .container-fluid {
    padding: 15px;
}

.master-content .ct-content {
    background-color: #FFFFFF;
    padding: 15px;
    margin-bottom: 5px;
}

.master-content .ct-content-full {
    background-color: #FFFFFF;
    padding: 15px;
    margin: 0 -15px;
}

.master-content .container-fluid .left-col {
    padding-right: 7.5px;
}

.master-content .container-fluid .right-col {
    padding-left: 7.5px;
}




@import "./components/custom-switch.css";
@import "./components/table.css";
@import "./components/pagination.css";
@import "./components/content-info.css";
@import "./components/button.css";
@import "./components/box-list.css";
@import "./components/timeline.css";

.row-gutters {
    margin-right: -15px;
    margin-left: -15px;
}

.ct-content h2 {
    font-weight: 700;
    font-size: 16px;
}

.ct-content .form-group {
    margin-bottom: 5px;
}

.ct-content .form-group label {
    font-weight: 700;
}

.ct-content .form-group input,
.ct-content .form-group textarea,
.ct-content .form-group select {
    border-radius: 0;
    padding: 0px 5px;
    font-size: 13px !important;
    height: 30px;
    border: 1px solid #dcdcdc;
}

.ct-content .form-group textarea {
    min-height: 66px;
}

.ct-content .content-top-right-button {
    position: absolute;
    top: 10px;
    right: 30px;
    z-index: 1;
}

.ct-content .content-top-right-button button {
    border-radius: 50px;
}

.ct-content .content-top-right-button .btn {
    margin-right: 10px;
}

.ct-content .content-top-right-button .btn:last-child {
    margin-right: 0;
}


.ct-form-button-group {
    max-width: 400px;
    display: flex;
    flex-wrap: wrap;
    margin-right: -3px;
    margin-left: -3px;
    margin: 0 auto;
}

.ct-form-button-group button {
    flex-basis: 0;
    flex-grow: 1;
    max-width: 100%;
    margin-left: 3px;
    margin-right: 3px;
}

.ct-form-button {
    border-radius: 5px;
    font-weight: 700;
    color: #fff;
    border: 0;
}

.ct-content-toolbar .standart-top-filter button {
    margin-left: 10px;
}

.badge-status.h_status_0,
.badge-status.pending {
    background: #9d446b;
    color: #fff;
}

.badge-status.modify {
    background: #4e2235;
    color: #fff;
}

.badge-status.h_status_4,
.badge-status.cancelled,
.badge-status.unapproved,
.badge-status.payment_refunded {
    background: #ce291d;
    color: #fff;
}

.badge-status.h_status_1,
.badge-status.status_1,
.badge-status.active,
.badge-status.approved,
.badge-status.completed {
    background: #008f29;
    color: #fff;
}

.badge-status.h_status_2,
.badge-status.status_0,
.badge-status.deactive {
    background: #000000;
    color: #fff;
}

.badge-status.h_status_3,
.badge-status.expired {
    background: #3f3f3f;
    color: #fff;
}

.badge-status.payment_expected {
    background: rgb(244, 148, 0);
}



.ct-container .nav-tabs .nav-link {
    border: none;
    background-color: rgb(227, 225, 222);
    padding: 10px 30px 10px 30px;
    font-size: 16px;
    border-radius: 0;
    font-family: AvenirNext-Medium;
    color: #666666;
}

.ct-container .nav-tabs .nav-link.active {
    background-color: #FFFFFF;
    color: rgb(92, 181, 232);
    border-color: #ffffff;
    position: relative;
    font-family: AvenirNext-DemiBold;
}

.ct-container .nav-tabs .nav-link.active:after {
    position: absolute;
    display: block;
    height: 1px;
    background: rgb(92, 181, 232);
    margin-top: 9px;
    content: ' ';
    width: calc(100% - 60px);
}

.ct-container .tab-content {
    border: none;
    margin-bottom: 5px;
}

.react-datetime-picker__inputGroup__input {
    border: 0 !important;
    padding: 1px;
}

.ct-tab-style-1 {
    width: 100%;
}


.ct-tab-style-1 .tab-content {
    border: 0;
}

.ct-tab-style-1 .tab-content .tab-pane {
    padding: 0;
}

.ct-tab-style-1 .nav-tabs .nav-item {
    z-index: 99;
    margin-bottom: -2px;
    min-width: 100px;
    text-align: center;
    padding: 0 5px;
}

.ct-tab-style-1 .nav-tabs .nav-item .nav-link {
    border: 0;
}

.ct-tab-style-1 .nav-tabs .nav-item .nav-link.active {
    border: 0;
    border-bottom: 4px solid #fcb225;
    font-weight: bold;
}