.ct-boxselect {
    margin-right: -3px !important;
    margin-left: -3px !important;
}

.ct-boxselect-item {
    padding: 12px 5px;
    cursor: pointer;
    background: linear-gradient(90deg, rgba(67, 74, 82, 1) 0%, rgba(105, 111, 118, 1) 100%);
    float: left;
    color: #fff;
    font-weight: bold;
    border-radius: 7px;
    margin-left: 3px;
    margin-right: 3px;
    margin-bottom: 5px;
    border: 1px solid #c8cacd;
    font-size: 12px;
    text-align: center;
    padding-right: 15px;
    padding-left: 15px;
}

.ct-boxselect-item.selected {
    background: #8bc926;
    border: 1px solid #d3eab2;
}

.ct-boxselect-item:hover {}


.ct-boxselect-item span {
    display: block;
    font-size: 10px;
    margin-top: 5px;
    font-family: "AvenirNext";
}

.ct-boxselect-item ul {
    display: block;
    margin: 0;
    padding: 0;
    list-style: none;
    background: #e7e7e7;
    margin-left: -15px;
    margin-right: -15px;
    margin-bottom: -12px;
    padding-top: 5px;
    border-radius: 0 0 6px 6px;
    min-width: 100px;
}

.ct-boxselect-item ul li {
    display: block;
    text-align: center;
    color: #000;
    font-size: 12px;
}

.ct-boxselect-item ul li span {
    font-weight: 700;
    font-size: 13px;
}