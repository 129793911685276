
.btn-back {
    position: relative;
    background: #f53d3b;
    background: linear-gradient(0deg, #f53d3b 0, #ae2826 100%);
}

.btn-submit {
    position: relative;
    background: #8bc926;
    background: linear-gradient(0deg, #8bc926 0, #608d17 100%);
}

.ct-button {
    font-size: 14px !important;
    border-radius: 14px !important;
    padding: 6px !important;
    min-width: 26px;
    min-height: 26px;
}

.ct-button .btn-text {
    width: 0;
    overflow: hidden;
    float: left;
    transition: width 0.5s ease-out;
    text-align: center;
    font-family: AvenirNext;
    font-size: 14px;
    font-weight: 700;
    line-height: 16px;
    white-space: pre;
}

.ct-button .btn-icon {
    float: left;
    width: 10px;
    margin-left: 2px;
    margin-right: 2px;
}

.ct-button:hover {
    color: #fff;
    transition-duration: 0.5s;
}

.ct-button:hover .btn-text {
    width: 65px;
    padding-left: 5px;
}

.ct-button.open .btn-text {
    width: auto;
    padding-left: 5px;
    padding-right: 5px;
}

.btn.btn-edit {
    background-color: #f49400;
    border-color: #db8500;
    color: #fff;
}

.btn.btn-edit:hover {
    background-color: #c37600;
    border-color: #ce8e2e;
}

.btn.btn-modify {
    background-color: #743869;
    border-color: #68325e;
    color: #fff;
}

.btn.btn-modify:hover {
    background-color: #5c2c54;
    border-color: #512749;
}

.btn.btn-delete {
    background-color: #f03e3a;
    border-color: #f88082;
    color: #fff;
}

.btn.btn-delete:hover {
    background-color: #b12715;
    border-color: #d02a1f;
}

.btn.btn-add {
    background-color: rgb(122, 204, 65);
    border-color: rgb(128, 209, 70);
    color: #fff;
}

.btn.btn-add:hover {
    background-color: rgb(102, 180, 46);
    border-color: rgb(90, 161, 39);
}

.btn.btn-success {
    background-color: rgb(122, 204, 65);
    border-color: rgb(122, 204, 65);
}

.btn.btn-order-login {
    background-color: #426eff;
    border-color: #426eff;
    color: #fff;
}

.btn.btn-order-login:hover {
    background-color: #4b75ff;
    border-color: #4b75ff;
}