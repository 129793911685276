
.content-info .head-info {
    margin-bottom: 20px;
}

.content-info .head-info h1 {
    font-size: 24px;
    font-family: AvenirNext-DemiBold;
    margin-bottom: 0;
}

.content-info .head-info h3 {
    font-size: 16px;
    font-family: AvenirNext-DemiBold;
    color: #808080;
    margin-bottom: 0;
}

.content-info .head-info .badge-status {
    padding: 5px 10px;
    border-radius: 12px;
}

.content-info .icon-info {
    width: 50%;
}

.content-info .icon-info .fa-layers {
    width: 20px;
    height: 20px;
    float: left;
}

.content-info .icon-info .fa-layers .fa-circle {
    color: #743869;
}

.content-info .icon-info label {
    font-size: 12px;
    line-height: 20px;
    float: left;
    margin-left: 5px;
}

.content-info .icon-info .badge-icon {
    background: #743869;
    color: #fff;
    padding: 5px 10px;
    border-radius: 12px;
    margin-bottom: 10px;
}