/*checkbox başlangıç*/
.custom-switch {
    padding: 0;
    width: 54px;
    height: 22px;
    margin-left: 0px;
    border: 2px solid transparent;
    border-radius: 10px;
}

.custom-switch:before {
    content: '';
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    margin: -3px;
    /* !importanté */
    border-radius: inherit;
    /* !importanté */
    background: linear-gradient(to right, #e8e8e8, white);
    height: 24px;
}

.custom-switch .custom-control-label::after {
    width: 25px;
    height: 18px;
    background-color: #fff;
    box-shadow: 0px 1px 3px 0px rgba(150, 150, 150, 0.75);
    left: 0;
    top: 0;
    background: linear-gradient(129deg, rgba(255, 255, 255, 1) 0%, rgba(255, 255, 255, 1) 43%, rgba(196, 196, 196, 1) 100%);
    cursor: pointer;
}

.custom-switch .custom-control-label::before {
    width: 50px;
    height: 18px;
    top: 0;
    left: 0;
    border: 0.3px solid #efefef;
}

.custom-switch .custom-control-input:checked~.custom-control-label::after {
    transform: translateX(25px);
    width: 25px;
    height: 18px;
    left: 0;
    top: 0;
    box-shadow: 0px 1px 3px 0px rgba(150, 150, 150, 0.75);
    background: linear-gradient(129deg, rgba(255, 255, 255, 1) 0%, rgba(255, 255, 255, 1) 43%, rgba(196, 196, 196, 1) 100%);
    cursor: pointer;
}

.custom-control-input:checked~.custom-control-label::before {
    color: #fff;
    background-color: rgb(122, 204, 65);
    width: 50px;
    height: 18px;
    left: 0;
    top: 0;
    border: 0.3px solid rgb(122, 204, 65);
}

.custom-control-input:focus:not(:checked)~.custom-control-label::before,
.custom-control-input:focus~.custom-control-label::before {
    border-color: #efefef;
    box-shadow: unset;
}

/*checkbox bitiş*/