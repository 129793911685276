.home-container {
    width: 100%;
    height: calc(100vh - 70px);
    margin-top: -15px;
}

.home-container .home-title {
    text-align: center;
    margin-top: 30vh;
}

.home-container .home-title h1,
.home-container .home-title p {
    color: #C23157;
    background: linear-gradient(to bottom, #FF186D, #514F91);
    background-clip: text;
    -webkit-text-fill-color: transparent;
}

.home-container h4 {
    padding-left: 15px;
}

.home-container .ct-content-full {
    padding-bottom: 0!important;
}

.home-container .home-bottom-chart{
    margin-top:15px;
}

.home-container h5 {
    text-align: center;
}

.home-container .history-scroll{
    width: 100%;
    height: 480px;
    overflow-y: auto;
    padding: 0 0 0 15px;
}

.home-container .history-scroll .ct-minitimeline .event .event-content {
    padding-bottom: 9px;
}